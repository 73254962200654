.amplify-button--primary {
    position : relative;
    top: 40px;
    border-radius: 30px;
    background-color: blue;
    height: 55px;
    font-size:18px;
}

/*  */

.amplify-button--primary:hover {
    background-color: white;
    color: blue;
    border:1px solid blue;
}

.amplify-button--link {
    background-color: white,
}

.amplify-input {
    border-radius : 0;
    border-left:0;
    border-right:0;
    border-top:0;
}

.amplify-input:focus {
    box-shadow:none;
}

.amplify-field__show-password {
    border-radius : 0;
    border-left:0;
    border-right:0;
    border-top:0;
}

.amplify-field__show-password:hover {
    background-color:white;
}

.amplify-field__show-password:focus {
    background-color:white;
    box-shadow: none;
}

.upper-text {
    font-size : 19px;
    font-weight: 500;
    margin:10px;
}

.lower-text {
    font-size: 17px;
    font-weight : 200;
}

.data-amplify-router {
    border:0;
}

div[data-amplify-router] {
    margin-top:40px;
    border:0 !important;
    box-shadow: none !important;
}

.amplify-tabs {
    box-shadow: 0px 15px 10px -15px #e2e2e4;
}

.amplify-flex {
    justify-content: space-around;
}

.amplify-tabs-item {
    background-color:white !important;
    color:black;
}

.amplify-tabs-item:hover {
    color:black;
}

div[data-indicator-position=bottom] > .amplify-tabs-item {
    border-width: var(--amplify-components-tabs-border-width) 0 0 0;
}

.amplify-tabs-item[data-state=active] {      
    color : black;
    border-color: blue;
}

.MuiTypography-h5 {
    margin-top:15px;
    font-size:1.2rem !important;
}

.character > div > svg {
    width: 80px;
    height : 100px
}
/* .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    position: absolute !important;
    inset :  auto !important;
    top: 70px !important;
    margin: 0px !important;
  }

@media screen and (max-width: 1080px) {
    .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
        position: absolute !important;
        inset :  auto !important;
        top: 70px !important;
        right: 0px !important;  
        margin: 0px !important;
      }
  }
  @media screen and (max-width: 1280px) {
    .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
        position: absolute !important;
        inset :  auto !important;
        top: 70px !important;
        right: 0px !important;  
        margin: 0px !important;
      }
  } */