.cropper-crop-box, .cropper-view-box {
    border-radius: 50%;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #060543;
    outline: 0;
}
 
.cropper-face {
  background-color:inherit !important;
}
.cropModalBtn button {
  padding: 8px 11px;
  min-width: initial;
}
.cropModalBtn button:nth-child(5) {
  color:red;
}
.cropModalBtn button:nth-child(5):hover {
  color:red;
}
.cropModalBtn button:hover {
  background-color: transparent;
}
.cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {
  display:none !important;
}

.cropper-view-box {
  outline:inherit !important;
}
.root{
  position: relative;
}

.uploadingImageLoader {
  background-color: #fff;
  text-align: center;
  max-width:100%;
  height:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.uploadingImageLoader img {
  width:80%;
  vertical-align: center;
}