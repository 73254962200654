@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  position: absolute;
  right: 10%;
  width: 20px;
  height: 20px;
  border: 3px solid white; /* Light grey */
  border-top: 3px solid transparent; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  /* height: 350px; */
}
